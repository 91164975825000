//Logo's
import logo from './Logo.svg';

//Icons
import TelIcon from './Tel.svg';
import MailIcon from './Mail.svg';
import companyname from './HFE.svg';

//CSS
import './App.css';


function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className="App-card">
          <div className="App-logo"><img src={logo} alt="Logo Huwelijksfotograaf Ellen"/></div>
          <div className="App-company">
            <img src={companyname} alt="Huwelijksfotograaf Ellen"/>
              <p>Ellen Van den Bergh
                <div className="horizontal-line"></div>
                Fotograaf
              </p>
              <div className='App-contactinfo'>
                <p>
                <div onClick={(e) => {window.location.href ='tel:+32468260802';}}>
                <img src={TelIcon} alt="Telefoon nummer Huwelijksfotograaf Ellen" width={50} height={50}/>+32 468 26 08 02
                </div>                  
                </p>
                <p>
                <div onClick={(e) => {window.location.href ='mailto:info@huwelijksfotograaf-ellen.be';}}>
                <img src={MailIcon} alt="E-mailadres Huwelijksfotograaf Ellen"  width={50} height={50}/>info@huwelijksfotograaf-ellen.be
                </div>
                </p>
              </div>              
          </div>      
        </div>
      </div> 
    </div>
    
  );
}

export default App;

